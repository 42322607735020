import React from 'react';

import { ToastContainer } from 'react-toastify';
import Layout from 'src/components/layout/layout';
import { PrivateRoute } from 'src/components/private-route/private-route';
import MemberSettings from 'src/components/saas-memberships/member-settings/member-settings';
import SEO from 'src/components/seo';

const SaasMembershipSettingsPage: React.FC = () => {
  return (
    <Layout>
      <SEO title="Saas Membership Settings" />
      <PrivateRoute>
        <ToastContainer hideProgressBar pauseOnHover theme="colored" position="top-right" />
        <MemberSettings />
      </PrivateRoute>
    </Layout>
  );
};

export default SaasMembershipSettingsPage;
