import React from 'react';

import Grid from '@mui/material/Grid';
import { navigate } from 'gatsby';
import { useTranslation } from 'react-i18next';
import { Button } from 'src/components/button/link';
import Panel from 'src/components/panel/panel';

import * as styles from './member.settings.module.less';

interface SettingsItemProps {
  linkSrc: string;
  title: string;
}
const SettingsItem: React.FC<SettingsItemProps> = ({ children, linkSrc, title }) => {
  const { t } = useTranslation();
  return (
    <Grid container item xs={6} sm={6} md={4} lg={4}>
      <Panel title={title} classNames={{ wrapper: styles.panel, container: styles.container }}>
        {children}
        <Grid container>
          <Button onClick={() => navigate(linkSrc)} level="secondary">
            {t('manage')}
          </Button>
        </Grid>
      </Panel>
    </Grid>
  );
};

export default SettingsItem;
