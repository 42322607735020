import React from 'react';

import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';
import PageHeader from 'src/components/page-header/page-header';
import { Body } from 'src/components/typography/typography';
import { PageMaxWidth } from 'src/components/util-components/util-components';
import ROUTES from 'src/utils/routes';

import SettingsItem from './settings-item';

const MemberSettings: React.FC = () => {
  const { t } = useTranslation();
  return (
    <PageMaxWidth>
      <Grid container justifyContent="space-between" alignItems="center">
        <PageHeader title={t('membership settings')} />
      </Grid>

      <Grid container direction="row" alignItems="stretch" spacing={3}>
        <SettingsItem linkSrc={ROUTES.SAAS_MEMBERSHIP_SETTINGS_INVOICE_DETAILS} title={t('invoice details')}>
          <Body size="md" spacing={{ margins: { sm: ['top', 'bottom'] } }}>
            {t('invoice details text')}
          </Body>
        </SettingsItem>
        <SettingsItem
          linkSrc={ROUTES.SAAS_MEMBERSHIP_SETTINGS_TERMS_AND_CONDITIONS}
          title={t('terms and conditions label')}
        >
          <Body size="md" spacing={{ margins: { sm: ['top', 'bottom'] } }}>
            {t('terms and conditions text')}
          </Body>
        </SettingsItem>
        <SettingsItem linkSrc={ROUTES.SAAS_MEMBERSHIP_SETTINGS_STRIPE_SETUP} title={t('stripe setup')}>
          <Body size="md" spacing={{ margins: { sm: ['top', 'bottom'] } }}>
            {t('stripe setup text')}
          </Body>
        </SettingsItem>
      </Grid>
    </PageMaxWidth>
  );
};

export default MemberSettings;
